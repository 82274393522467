@import 'styles/mixins';

.collapse {
  &__body {
    overflow: hidden;
    transition: .2s;
    opacity: 0;
  }

  &__toggle-btn {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: unset;
    border: 0;
    padding: 0;
    line-height: initial;
    font-weight: 600;
    cursor: pointer;

    @include screen-mobile {
      font-size: 14px;
    }
  }

  &__icon {
    font-size: 14px;
    margin-right: 5px;

    @include screen-mobile {
      font-size: 12px;
    }
  }
}
