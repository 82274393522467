@import 'styles/variables';
@import 'styles/mixins';

.landing {
  &__intro {
    color: $color-blue;
    text-align: center;

    &__title,
    &__sub-title {
      font-weight: 800;
      line-height: normal;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 8px;

      @include screen-mobile {
        font-size: 20px;
        margin-bottom: 5px;
      }
    }

    &__sub-title {
      font-size: 20px;

      @include screen-mobile {
        font-size: 16px;
      }
    }
  }

  &__main {
    display: flex;
    align-items: center;
    margin: 35px -15px 0 -15px;

    @include screen-desktop-xxl-down {
      flex-wrap: wrap;
    }

    @include screen-mobile {
      flex-direction: column;
      margin: 10px 0;
    }
  }

  &__info {
    width: 30%;
    padding: 0 15px;

    @include screen-desktop-xxl-down {
      width: 100%;
      margin-top: 30px;
    }

    @include screen-mobile {
      margin-top: 15px;
      padding: 0;
    }

    &__item {
      margin-bottom: 20px;

      @include screen-mobile {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 700;
      color: $color-blue;
      line-height: normal;
    }

    &__content {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .card {
    &--landing {
      width: 480px;
      text-align: center;
      margin-right: auto;
      margin-left: auto;

      @include screen-desktop-down {
        width: calc(100% / 2 - 15px);
      }

      @include screen-mobile {
        order: -1;
        width: 100%;
      }
    }
  }

  .form {
    &--landing {
      .btn-submit {
        margin-top: 10px;
        float: left;

        @include screen-mobile {
          margin-top: 0;
        }

        &-wrapper {
          overflow: auto;
        }
      }
    }

    &__label-container {
      &--landing {
        text-align: left;
        margin-bottom: 5px;
      }
    }
  }

  .tooltip {
    &--landing {
      margin-left: 5px;
      display: inline-block;
    }
  }
}

.landing__adv {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &-wrap {
    width: 30%;
    padding: 0 15px;
  }

  &-tag {
    position: absolute;
    top: -15px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 10px;
    color: $color-blue;
    background-color: $color-grayscale;
    border-radius: 999px;
    font-size: 16px;
    font-weight: 600;

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &-left {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 38px 24px 0;
    font-size: 18px;

    .title {
      font-size: 18px;
      font-weight: 800;
    }

    .desc {
      margin: 16px 0 24px;
    }

    .button {
      text-decoration: none;
      line-height: 30px;
      width: 100%;
    }
  }

  &-right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-background {
      width: 100%;
      height: 100%;
    }

    .img-items {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }

  @include screen-desktop-xxl-down {
    &-wrap {
      width: 40%;
    }
  }

  @include screen-desktop-down {
    &-wrap {
      width: calc(100% / 2 - 15px);
    }
  }

  @include screen-mobile {
    &-left {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 16px;

      .title {
        font-size: 16px;
      }
    }

    &-wrap {
      width: 100%;
      padding: 0;
      margin-top: 24px;
    }
  }
}
