@import 'styles/variables';
@import 'styles/mixins';

.header {
  &::before {
    content: '';
    display: block;
    height: 20px;
    background-color: $color-brand;
    margin: auto;

    @include screen-mobile {
      height: 15px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    height: auto;

    @include screen-mobile {
      justify-content: center;
      flex-direction: column;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  &__logo {
    margin: 3px 0;
    height: 42px;
    width: auto;

    @include screen-mobile {
      height: 38px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    max-width: 195px;

    @media (max-width: $screen-mobile) {
      display: none;
    }

    @include screen-desktop-down {
      margin: 5px 0;
    }

    @include screen-mobile {
      max-width: 155px;
    }

    &__icon {
      font-size: 20px;
      margin: 0 15px;
      color: $color-blue;

      @include screen-mobile {
        font-size: 20px;
        margin: 0 10px;
      }
    }

    &__text {
      font-weight: bold;
      font-size: 18px;
      line-height: normal;
      color: $color-blue;

      @include screen-mobile {
        font-size: 16px;
      }
    }
  }

  &__translate {
    width: 30px;
    cursor: pointer;

    @include screen-mobile {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }
}
