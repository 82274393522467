@import 'styles/variables';
@import 'styles/mixins';

.container {
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding-right: 60px;
    padding-left: 60px;

    @include screen-mobile {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.link {
    text-transform: unset;
    color: inherit;
    text-decoration: unset;
    white-space: nowrap;

    &--underline {
        text-decoration: underline;
    }
}

.page-title {
    font-size: 32px;
    margin-bottom: 10px;

    @include screen-mobile {
        font-size: 24px;
    }
}

.page-sub-title {
    font-size: 20px;

    @include screen-mobile {
        font-size: 16px;
    }
}

.page-title,
.page-sub-title {
    font-weight: 800;
    line-height: normal;
    color: $color-blue;
    text-align: center;
}

.number {
    font-family: $font-family-primary;
}

:disabled,
.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
}
