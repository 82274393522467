@import 'styles/variables';
@import 'styles/mixins';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .body {
    flex: 1;
    background-color: $color-grey-light;
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    padding-bottom: 50px;

    @include screen-mobile {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
