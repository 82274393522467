@import 'styles/variables';
@import 'styles/mixins';

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  &__icon {
    font-size: 30px;
    color: $color-white;

    @include screen-mobile {
      font-size: 18px;
    }

    &-wrapper {
      background-color: $color-brand;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      @include screen-mobile {
        height: 30px;
        width: 30px;
        margin-bottom: 15px;
      }
    }
  }

  &__text {
    font-size: 25px;
    font-weight: 500;
    color: $color-blue;
    text-align: center;
    margin-bottom: 10px;
    line-height: normal;

    @include screen-mobile {
      font-size: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn {
    margin-top: 25px;
  }
}
