@import 'styles/variables';

// Media queries
@mixin screen-mobile {
  @media screen and (max-width: calc(#{$screen-tablet} - 1px)) {
    @content;
  }
}

@mixin screen-tablet {
  @media screen and (min-width: $screen-tablet) and (max-width: calc(#{$screen-desktop} - 1px)) {
    @content;
  }
}

@mixin screen-desktop {
  @media screen and (min-width: $screen-desktop) {
    @content;
  }
}

@mixin screen-desktop-xxl {
  @media screen and (min-width: $screen-desktop-xxl) {
    @content;
  }
}

@mixin screen-tablet-down {
  @media screen and (max-width: $screen-tablet) {
    @content;
  }
}

@mixin screen-desktop-down {
  @media screen and (max-width: $screen-desktop) {
    @content;
  }
}

@mixin screen-desktop-xxl-down {
  @media screen and (max-width: $screen-desktop-xxl) {
    @content;
  }
}

// Arrow
@mixin arrow($color, $size, $orientation) {
  width: 0;
  height: 0;
  @if $orientation == top {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $orientation == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $orientation == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  }
}
