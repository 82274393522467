@import 'styles/variables';
@import 'styles/mixins';

.button {
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  min-width: 200px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.5;
  transition: 0.3s ease-out;
  outline: unset;
  min-height: 50px;

  &--primary,
  &--secondary,
  &--primary-blue {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    transition-property: background-color;

    @include screen-mobile {
      min-height: 40px;
      padding: 10px;
      font-size: 12px;
    }
  }

  &--primary {
    color: $color-white;
    background-color: $color-brand;
    border: 2px solid $color-brand;

    @include screen-desktop {
      &:hover {
        font-size: 16px;
        font-weight: 700;
        color: $color-brand;
        background-color: $color-white;
      }
    }
  }

  &--primary-blue {
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      background-color: #204180;
    }
  }
}
