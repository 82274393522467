@import 'styles/variables';
@import 'styles/mixins';

$tooltip-border-width: 1px;
$tooltip-arrow-width: 5px;

.tooltip {
  text-align: center;

  &__label {
    width: 15px;
    height: 15px;
    background-color: $color-blue;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;

    &__inner {
      font-family: $font-family-primary;
      font-size: 10px;
      font-weight: 700;
      line-height: 1.4;
      color: $color-white;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__content {
    font-size: 12px;
    padding: 8px;
    border-radius: 3px;
    position: fixed;
    top: 0;
    left: 20px;
    background-color: $color-grey-light-50;
    max-width: 280px;
    width: 100%;
    border: $tooltip-border-width solid $color-grey-light-100;
    box-shadow: 2px 1px 7px 1px $color-black-one;
    text-align: left;

    @include screen-mobile {
      max-width: 180px;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
    }

    &-wrapper {
      position: relative;
      z-index: $z-index-tooltip;
    }

    &--right {
      &::after,
      &::before {
        transform: translate(-100%, -50%);
        top: 50%;
      }

      &::after {
        @include arrow($color-grey-light-50, $tooltip-arrow-width, left);
        left: 0;
      }

      &::before {
        @include arrow($color-grey-light-100, $tooltip-arrow-width, left);
        left: -#{$tooltip-border-width};
      }
    }

    &--left {
      &::after,
      &::before {
        transform: translate(100%, -50%);
        top: 50%;
      }

      &::after {
        @include arrow($color-grey-light-50, $tooltip-arrow-width, right);
        right: 0;
      }

      &::before {
        @include arrow($color-grey-light-100, $tooltip-arrow-width, right);
        right: -#{$tooltip-border-width};
      }
    }

    &--top {
      &::after,
      &::before {
        transform: translate(-50%, 100%);
        left: 50%;
      }

      &::after {
        @include arrow($color-grey-light-50, $tooltip-arrow-width, down);
        bottom: 0;
      }

      &::before {
        @include arrow($color-grey-light-100, $tooltip-arrow-width, down);
        bottom: -#{$tooltip-border-width};
      }
    }

    &--bottom {
      &::after,
      &::before {
        transform: translate(-50%, -100%);
        left: 50%;
      }

      &::after {
        @include arrow($color-grey-light-50, $tooltip-arrow-width, top);
        top: 0;
      }

      &::before {
        @include arrow($color-grey-light-100, $tooltip-arrow-width, top);
        top: -#{$tooltip-border-width};
      }
    }
  }
}
