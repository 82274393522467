@import 'styles/variables';
@import 'styles/mixins';

.modal {
  position: fixed;
  z-index: -10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s;

  &.show {
    z-index: $z-index-modal;
    opacity: 1;
  }

  &__overlay {
    position: absolute;
    z-index: $z-index-modal-overlay;
    top: 0;
    left: 0;
    background: $color-black-five;
    height: 100%;
    width: 100%;
  }

  &__dialog {
    position: absolute;
    z-index: $z-index-modal-dialog;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: $color-white;
    padding: 15px 20px 20px;
    border-radius: 5px;
    max-width: calc(100% - 30px);

    @include screen-mobile {
      padding: 10px 15px 15px;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__title {
      font-size: 20px;
      line-height: normal;
      font-weight: 700;
      padding: 0 10px 10px 0;

      @include screen-mobile {
        font-size: 18px;
        margin-top: 5px;
      }
    }

    &__close {
      font-size: 18px;
      padding: 0 5px;
      cursor: pointer;
    }
  }

  &__body {
    font-size: 16px;
    padding: 15px 0;
    text-align: center;

    @include screen-mobile {
      padding: 10px 0;
    }

    &__title {
      font-size: 20px;

      @include screen-mobile {
        font-size: 17px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    margin: 0 -5px;

    .button--modal {
      margin: 0 5px;

      @include screen-mobile {
        min-width: 120px;
      }
    }
  }
}
