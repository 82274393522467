@import './styles/reset';
@import './styles/utils';

// Global styles
html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $font-family-brand;
  font-size: 1.4rem;
}
