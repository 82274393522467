@import 'styles/variables';

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-black-three;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: $z-index-loading;

  &__icon {
    font-size: 40px;
    color: $color-white;
  }
}
