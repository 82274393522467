@import 'styles/mixins';
@import 'styles/variables';

.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &__body {
    text-align: center;
    margin-top: 20px;
    width: 100%;
    @include screen-mobile {
      margin-top: 10px;
    }
  }

  .number--payment {
    display: inline-block;
  }

  &__submit {
    margin-top: 15px;
  }

  &__iframe {
    border-radius: 10px;
    background-color: $color-white;
    height: 575px;
    width: 100%;
    border: 0;

    &-wrapper {
      width: 1000px;
      margin: auto;

      @include screen-tablet {
        width: 500px;
      }

      @include screen-mobile {
        width: 100%;
      }
    }
  }
}
