@import 'styles/variables';
@import 'styles/mixins';

.legal {
  font-size: 15px;
  margin-top: -35px;
  background-color: $color-white;

  @include screen-mobile {
    font-size: 14px;
    margin-top: -20px;
  }

  &__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../../assets/images/legal-banner-mobile.jpeg') 50% no-repeat;
    height: 160px;
    background-size: cover;

    @include screen-desktop-xxl {
      display: block;
      height: 235px;
      background: url('../../../assets/images/legal-banner-desktop.jpeg') no-repeat;
      background-size: 130%;
      background-position: center 30%;
    }

    @include screen-mobile {
      height: 120px;
    }
  }

  .page-title--legal {
    font-size: 40px;
    color: $color-white;
    text-shadow: 3px 3px 1px $color-black-five;
    margin: 0;

    @include screen-desktop-xxl {
      padding-top: 64px;
    }

    @include screen-mobile {
      font-size: 24px;
    }
  }

  .container--legal {
    background-color: $color-white;

    @include screen-desktop-xxl {
      margin-top: -65px;
      max-width: 1366px;
      border-radius: 5px;
    }
  }

  &__intro {
    padding-top: 30px;

    @include screen-mobile {
      padding-top: 15px;
    }
  }

  &__list {
    margin-top: 30px;

    @include screen-mobile {
      margin-top: 20px;
    }
  }

  &__item {
    margin-bottom: 30px;

    @include screen-mobile {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 22px;
    color: $color-blue;
    font-weight: 700;
    margin-bottom: 10px;

    @include screen-mobile {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &__text {
    margin-bottom: 15px;

    @include screen-mobile {
      margin-bottom: 10px;
    }

    &--bold {
      font-weight: 600;
    }
  }

  &__link {
    font-weight: 600;
    word-break: break-all;
    white-space: nowrap;

    @include screen-mobile {
      white-space: unset;
    }
  }

  .number--legal {
    font-size: 15px;
  }
}
