@import 'styles/variables';
@import 'styles/mixins';

.app {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__card {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding-top: 20px;
            padding-bottom: 20px;

            @include screen-desktop-down {
                flex-wrap: wrap;
                padding-top: 15px;
                padding-bottom: 15px;
            }

            &__item {
                display: flex;
                align-items: flex-start;
                padding: 10px 15px;

                @include screen-desktop-down {
                    width: 50%;
                }

                @include screen-mobile {
                    width: 100%;
                }
            }

            &__body {
                margin-top: 5px;
                margin-left: 10px;

                @include screen-mobile {
                    margin-top: 0;
                }
            }

            &__title {
                font-size: 18px;
                font-weight: 800;
                color: $color-blue;

                @include screen-mobile {
                    font-size: 16px;
                }
            }

            &__icon {
                width: 30px;
                height: auto;
                object-fit: contain;

                @include screen-mobile {
                    width: 25px;
                }
            }

            &__info {
                color: $color-blue;
                font-weight: 400;
                width: 100%;
                font-size: 14px;
            }

            &__info-title {
                font-size: 16px;
                font-weight: 400;
                color: $color-text;

                @include screen-mobile {
                    font-size: 14px;
                }
            }

            &__link {
                text-decoration: none;
                color: inherit;
            }

            &__title,
            &__info,
            &__info-title {
                margin-bottom: 5px;

                @include screen-mobile {
                    margin-bottom: 2px;
                }
            }
        }

        &__nav {
            font-weight: 400;
            color: $color-white;
            background: $color-brand;
            width: 100%;
            text-align: center;
            font-size: 12px;
            padding: 30px 5px;

            @include screen-mobile {
                padding: 20px 5px;
            }

            &__link {
                text-decoration: underline;
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }

            &__text {
                margin-bottom: 5px;

                @include screen-mobile {
                    margin-bottom: 2px;
                }
            }
        }
    }
}