@import 'styles/variables';
@import 'styles/mixins';

.recap {
  font-size: 16px;
  margin-top: auto;
  margin-bottom: auto;

  @include screen-mobile {
    font-size: 14px;
  }

  &__icon {
    font-size: 40px;
    color: $color-blue;
    margin-bottom: 10px;

    @include screen-mobile {
      font-size: 30px;
      margin-bottom: 5px;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 35px;

    @include screen-mobile {
      margin-bottom: 10px;
    }
  }

  &__sub-title {
    color: $color-grey-light-150;
  }

  &__body {
    width: 670px;
    margin: auto;

    @include screen-mobile {
      width: 100%;
    }
  }

  &__greet {
    font-size: 18px;
    font-weight: 700;
    color: $color-blue;
    margin-bottom: 10px;

    @include screen-mobile {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &__content {
    font-weight: 400;
    color: $color-text;
  }

  &__btn {
    margin-top: 20px;
    width: 375px;

    @include screen-mobile {
      width: auto;
      padding: 10px 8px;
    }

    &-wrapper {
      text-align: center;
      .form__error {
        margin-top: 20px;
      }

      & + .recap__email__success {
        margin-top: 40px;
      }
    }
  }

  &__email-loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;

    .loading__icon {
      color: $color-grey-light-150;
    }
  }

  &__email {
    margin-top: 35px;

    @include screen-mobile {
      margin-top: 25px;
    }

    &__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      color: $color-blue;
      margin-bottom: 10px;
    }

    &__content {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .form {
      &__group--email {
        margin-bottom: 10px;
      }

      &__field {
        &--confirmation {
          margin: 0 5px 0 0;
        }
      }

      &__error {
        font-size: 14px;
      }

      &__link {
        color: $color-blue;
        text-decoration: underline;
        word-break: break-all;
        white-space: nowrap;
        word-break: normal;

        @include screen-mobile {
          white-space: unset;
        }
      }
    }

    &__error,
    &__success {
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 5px;
      color: $color-black;
      font-weight: bold;
    }

    &__success {
      border: 1px solid $color-success;
      background: $color-success-20;
    }

    &__error {
      border: 1px solid $color-error;
      background: $color-pink-grey;
    }
  }  
}
