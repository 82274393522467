$color-brand: #e2000f;
$color-blue: #001e5f;
$color-grey-light: #fafafa;
$color-grey-light-50: #f0f0f0;
$color-grey-light-100: #cbcbcb;
$color-grey-light-150: #c0c0c0;
$color-grey-light-200: #808080;
$color-grayscale: #e1e8ee;
$color-white: #fff;
$color-text: #212529;
$color-error: #d62756;
$color-pink: #d62756;
$color-pink-grey: #ff94b175;
$color-black: #000;
$color-success: #367436;
$color-success-20: #d5efd5;

$color-white-four: rgba(171, 171, 171, 0.41);

$color-black-five: rgba(0, 0, 0, 0.5);
$color-black-three: rgba(0, 0, 0, 0.3);
$color-black-one: rgba(0, 0, 0, 0.1);

// Screens
$screen-mobile: 375px;
$screen-tablet: 768px;
$screen-desktop: 1070px;
$screen-desktop-xxl: 1400px;

$container-max-width: 1512px;

// Fonts
$font-family-brand: 'Raleway';
$font-family-primary: 'Barlow';

// z-index
$z-index-loading: 100;
$z-index-modal: 95;
$z-index-modal-overlay: 93;
$z-index-modal-dialog: 94;
$z-index-tooltip: 90;
