@import 'styles/variables';
@import 'styles/mixins';

.invoice {
  font-size: 16px;
  color: $color-text;

  &__body {
    display: flex;
    margin: 35px -15px 0;

    @include screen-mobile {
      margin-top: 10px;
    }

    @include screen-desktop-down {
      flex-direction: column;
    }

    .induction,
    .payment {
      width: 50%;
      padding: 0 15px;

      @include screen-desktop-down {
        width: 100%;
      }
    }

    .induction {
      margin-bottom: 20px;

      @include screen-mobile {
        margin-bottom: 10px;
      }

      &__greet {
        font-size: 18px;
        font-weight: 700;
        color: $color-blue;

        @include screen-mobile {
          font-size: 16px;
        }
      }

      &__content {
        font-weight: 400;
        color: $color-text;

        &__item {
          margin-top: 10px;

          @include screen-mobile {
            margin-top: 5px;
            font-size: 14px;
          }

          &--sign {
            font-weight: 700;
          }
        }
      }

      &--paid {
        .induction__content__item {
          margin-top: 20px;

          @include screen-mobile {
            margin-top: 10px;
          }
        }
      }
    }

    .payment {
      @include screen-mobile {
        padding-bottom: 0;
      }

      &__title {
        font-size: 18px;
        font-weight: 700;
        color: $color-blue;

        @include screen-mobile {
          font-size: 16px;
        }
      }

      &__content {
        justify-content: space-between;
        margin: 0 -15px;
        display: flex;
        align-items: flex-start;

        @include screen-mobile {
          flex-direction: column;
        }
      }
    }

    .transport {
      display: flex;
      align-items: center;
      width: 60%;

      &__icon {
        font-size: 24px;
        color: $color-blue;
        margin-right: 5px;
        width: 50px;
        height: auto;

        @include screen-mobile {
          width: 40px;
        }
      }

      &__type {
        font-weight: 700;
        color: $color-blue;

        &__name {
          color: $color-black;
          text-transform: uppercase;
        }
      }

      &__date {
        color: $color-grey-light-200;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .action {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      width: 40%;

      &__amount {
        font-size: 30px;
        font-weight: 700;
        color: $color-brand;
        line-height: normal;
      }

      &__button {
        margin: 15px 0;

        @include screen-mobile {
          margin: 10px 0;
        }
      }

      &__invoice {
        color: $color-blue;
        white-space: nowrap;
      }

      &__time {
        font-size: 14px;
        color: $color-grey-light-200;
      }
    }

    .transport,
    .action {
      padding: 0 15px;
      margin-top: 10px;

      @include screen-mobile {
        width: 100%;
      }
    }

    .block-title {
      font-size: 18px;
      font-weight: 700;
      color: $color-blue;

      @include screen-mobile {
        font-size: 16px;
      }
    }
  }

  &__warning-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    object-fit: cover;
    transform: translateY(5px);

    @include screen-mobile {
      width: 20px;
      height: 20px;
      transform: translateY(4px);
    }
  }

  &__footer {
    width: 70%;

    @include screen-desktop-down {
      width: 100%;
    }

    .panel {
      margin-bottom: 30px;

      @include screen-mobile {
        margin-bottom: 20px;
      }

      &__item {
        margin-top: 25px;

        @include screen-mobile {
          margin-top: 20px;
        }
      }

      &__title {
        font-size: 18px;
        font-weight: 700;
        color: $color-blue;
        display: flex;
        align-items: center;
        line-height: normal;

        @include screen-mobile {
          font-size: 14px;
        }
      }

      &__icon {
        font-size: 15px;

        &-wrapper {
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $color-blue;
          color: $color-white;
          border-radius: 50%;
          margin-right: 10px;
        }
      }

      &__content {
        margin-top: 10px;

        @include screen-mobile {
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }

    .info {
      margin-top: 10px;
      font-size: 14px;

      &__item {
        margin-top: 15px;

        &:first-child {
          margin-top: 0;
        }
      }

      &__main {
        font-weight: 600;

        &--address,
        &--bank {
          display: flex;
          align-items: center;
          padding: 5px 0;
        }

        &--bank {
          word-break: break-all;

          @include screen-mobile {
            font-size: 13px;
          }
        }

        &--company-name {
          white-space: nowrap;
        }
      }

      &__icon {
        display: inline-block;
        width: 28px;
        object-fit: cover;
        height: 30px;
        margin-right: 8px;

        &--address {
          height: 25px;
          width: 16px;
        }
      }
    }
  }
}
