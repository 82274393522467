@import 'styles/variables';
@import 'styles/mixins';
$width-checkbox-checkmark-before: 2px;
$dimension-checkbox-checkmark: 16px;

.form {
  &__label {
    font-size: 16px;
    font-weight: 800;
    color: $color-blue;
    display: inline;
    text-align: left;

    @include screen-mobile {
      font-size: 14px;
    }
  }

  &__label-container {
    display: flex;
  }

  &__field {
    &--number {
      font-family: $font-family-primary;
    }
  }

  .react-select__control,
  &__field[type='text'],
  &__field[type='number'],
  &__field[type='password'],
  &__field[type='search'],
  &__field[type='email'] {
    font-size: 16px;
    color: $color-text;
    font-weight: 500;
    min-height: 50px;
    padding: 10px;
    background-color: $color-white;
    border: 1px solid $color-white-four;
    border-radius: 5px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: unset;
    transition: 0.1s;

    @include screen-mobile {
      font-size: 14px;
      min-height: 43px;
    }

    &:hover,
    &:focus {
      border: 2px solid $color-blue;
      padding: 9px;
      outline: 0;

      &.form__field--error {
        border-color: $color-error;
      }
    }

    &::placeholder {
      color: $color-grey-light-100;
      font-family: $font-family-brand;
    }

    &.form__field--error {
      border-color: $color-error;
    }
  }

  // Checkbox
  .form__checkbox-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .form {
      &__field[type='checkbox'] {
        display: none;

        &:checked {
          & + .form {
            &__checkmark {
              background-color: $color-blue;
              border-color: $color-blue;

              &::before,
              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 10px;
              }

              &::before {
                width: $width-checkbox-checkmark-before;
                height: 7px;
                background-color: $color-white;
                left: 7px;
                top: 4px;
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
              }

              &::after {
                width: 4px;
                height: $width-checkbox-checkmark-before;
                background-color: $color-white;
                left: 3px;
                top: 7px;
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
        }

        &:disabled {
          & + .form {
            &__checkmark {
              opacity: 0.5;
              cursor: inherit;
            }
          }
        }
      }

      &__checkmark {
        display: inline-block;
        position: relative;
        height: $dimension-checkbox-checkmark;
        width: $dimension-checkbox-checkmark;
        border: 1px solid $color-white-four;
        border-radius: 3px;
        margin-right: 5px;
        margin-top: 2px;
        background-color: $color-white;
        transition: ease-in-out 0.3s;
        cursor: pointer;
        flex-shrink: 0;
      }

      &__field {
        &--error {
          & + .form__checkmark {
            border-color: $color-error;
          }
        }
      }

      &__label {
        font-size: 14px;
        font-weight: 400;
        color: $color-black;
      }
    }
  }

  &__group {
    margin-bottom: 20px;

    @include screen-mobile {
      margin-bottom: 15px;
    }
  }

  &__error {
    font-size: 16px;
    color: $color-error;
    font-weight: 400;
    text-align: left;
    margin-top: 5px;

    @include screen-mobile {
      font-size: 14px;
    }

    &--global {
      font-size: 14px;
      margin-bottom: 10px;
      border: 1px solid $color-pink;
      padding: 5px 10px;
      border-radius: 5px;
      color: $color-black;
      font-weight: bold;
      background: $color-pink-grey;
    }
  }

  // Overwrite React select library
  .form__field--error {
    .react-select__control {
      border: 1px solid $color-error;

      &--is-focused,
      &--menu-is-open,
      &:hover {
        padding: 9px;
        border-width: 2px;
      }
    }
  }

  .react-select {
    &__control {
      input {
        min-height: unset;
      }

      &--is-focused,
      &--menu-is-open {
        border: 2px solid $color-blue;
        padding: 9px;
      }
    }

    &__value-container {
      padding: 0;
      line-height: normal;

      & > div {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding: 0 3px;
    }

    &__menu {
      margin: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      text-align: left;
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      color: $color-text;

      &--is-focused {
        background-color: $color-grey-light-50;
      }

      &--is-selected {
        background-color: $color-blue;
        color: $color-white;
      }
    }

    &__placeholder {
      font-family: $font-family-brand;
      color: $color-grey-light-100;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-align: left;
    }
  }
}
