@import 'styles/variables';
@import 'styles/mixins';

.card {
  background-color: $color-white;
  border-radius: 10px;
  padding: 15px;
  width: 100%;

  @include screen-mobile {
    padding: 15px;
  }

  &--dark {
    background-color: $color-grey-light-50;
  }
}
